








































































































































import { Vue, Component } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscTable from "@/components/Table.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import SettingsButton from "@/components/Button/SettingsButton.vue";
import CurrentEmailDetailsForm from "@/components/CurrentEmailDetailsForm.vue";
import CreateNewEmailForm from "@/components/CreateNewEmailForm.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import { namespace } from "vuex-class";
import DropdownVerticalDotsButton from "@/components/Dropdown/DropdownVerticalDotsButton.vue";
import moment from "moment";
import _ from "lodash";
import RefreshButton from "@/components/Button/RefreshButton.vue";
import Actions from "@/components/Actions.vue";

const MailboxModule = namespace("mailbox");
const MailboxFilterModule = namespace("mailbox/localFilter");
const StudentModule = namespace("student");
const CostBearerModule = namespace("cost-bearer");
const TestingOrganizationsModule = namespace("testing-organizations");
const VatModule = namespace("vat");

@Component({
  components: {
    Actions,
    RefreshButton,
    FscPageHeader,
    FscSimpleCard,
    FscTable,
    CreateButton,
    RemoveButton,
    SettingsButton,
    CurrentEmailDetailsForm,
    CreateNewEmailForm,
    FilterAndSearch,
    DropdownVerticalDotsButton,
  },
})
export default class EmailClient extends Vue {
  public name = "EmailClient";

  @MailboxFilterModule.State("filter")
  public mailboxLocalFilter: any;

  @MailboxFilterModule.Mutation("SET_LOCAL_FILTER")
  public setMailboxLocalFilter!: (payload: Record<any, any>) => void;

  @VatModule.Action("filter")
  public filterStudents: any;

  @VatModule.Getter("getDataList")
  public allStudents: any;

  /* Getters start */
  @MailboxModule.Getter("getMailboxData")
  private emails: any;

  @MailboxModule.Getter("getTotalItems")
  private total: any;

  @MailboxModule.Getter("getEmailFilter")
  private emailAddresses: any;

  @MailboxModule.Getter("getMailboxFilter")
  private mailboxes: any;

  @MailboxModule.Getter("getSelectedEmailContent")
  private selectedEmailContent: any;

  @MailboxModule.Getter("getForceSyncByEmailConfigIdsLoading")
  private mailBoxLoading: any;

  @MailboxModule.Getter("getForceSyncByEmailConfigIdsSuccess")
  private getForceSyncByEmailConfigIdsSuccess: any;

  @MailboxModule.Getter("getEmailFilterIds")
  private getEmailFilterIds: any;

  @MailboxModule.Getter("getStudent")
  public getStudent: any;

  @MailboxModule.Action("setStudentInfo")
  public setStudentInfo: any;

  @MailboxModule.Action("setBlobFile")
  public setBlobFile: any;

  @MailboxModule.Getter("getBlob")
  public getBlob: any;

  @MailboxModule.Getter("getMimeType")
  public getMimeType: any;
  /* Getters end */

  /* Mutations start*/
  @MailboxModule.Mutation("SET_SELECTED_TARGET_EMAILS")
  private setSelectedTargetEmailsMutation: any;

  @MailboxModule.Mutation("SET_SELECTED_EMAIL_CONTENT")
  private setSelectedEmailContent: any;
  /* Mutations end*/

  /* Actions start */
  @MailboxModule.Action("fetchEmailsFilter")
  private fetchEmailsFilterAction: any;

  @MailboxModule.Action("fetchMailboxFilter")
  private fetchMailboxFilterAction: any;

  @MailboxModule.Action("switchMailStatusAction")
  private switchMailStatusAction: any;

  @MailboxModule.Action("filterByMailbox")
  private filterByMailboxAction: any;

  @MailboxModule.Action("removeEmail")
  public removeEmailAction: any;

  @MailboxModule.Action("forceSyncByEmailConfigIds")
  public forceSyncByEmailConfigIds!: (ids: Array<number>) => Promise<void>;
  /* Actions end */

  @CostBearerModule.Action("findOne")
  public findCostBearerByStudentId: any;

  @CostBearerModule.Getter("getDataItem")
  public costBearer: any;

  @MailboxModule.Getter("getCostBearer")
  public getCostBearerInfo: any;

  @MailboxModule.Getter("getAttachExamDocument")
  public getAttachExamDocument: any;

  @MailboxModule.Action("setAttachExamDocument")
  public setAttachExamDocument: any;

  @TestingOrganizationsModule.Action("filter")
  public findAllTestingOrganizations: any;

  @TestingOrganizationsModule.Getter("getDataList")
  public testingOrganizations: any;

  public mailOption = {};
  public search = "";
  public page = 0;
  public limit = 10;
  public debouncer: any;
  public selectedMailboxModel = { mailbox: "", id: 1 };
  public selectedEmailsModel = [];
  public selectedEmailId: any | null = null;
  public htmlReplyHeader = "";
  public studentEmail: any = null;
  public studentBlobFile: any = null;
  public studentMimeType: any = null;
  public costBearerEmail: any = null;

  public get time(): any {
    return (t: any) => moment({ hour: t.hour, minute: t.minute });
  }

  public get isMarkedEmailSelected(): boolean {
    return this.selectedEmailIds.includes("marked");
  }

  public mailOptions = [
    { label: "Als ungelesen markieren", value: "read_0" },
    { label: "Markiert", value: "marked_1" },
    { label: "Unmarkiert", value: "marked_0" },
  ];

  protected get selectedEmailIds(): Array<number | string> {
    return this.selectedEmailsModel.map((mail: any) => mail.id);
  }

  protected get selectedEmailIdsNoMarked(): Array<number | string> {
    return this.selectedEmailIds.filter((mail: any) => mail !== "marked");
  }

  public fields = [
    {
      key: "mailStatus",
      label: "",
      sortable: false,
    },
    {
      key: "sender",
      label: "",
      sortable: false,
    },
    {
      key: "actions",
      label: "",
      sortable: false,
    },
    {
      key: "incomingDateTime",
      label: "",
      sortable: false,
      align: "start",
    },
  ];

  public showNewEmailForm = false;
  public showCurrentEmailForm = false;
  public showTemplatesForm = false;

  /* methods start */
  public created() {
    this.debouncer = _.debounce((callback: any) => callback(), 500);
  }

  private once = true;

  public async fetchData() {
    await this.fetchMailboxFilterAction();
    await this.fetchEmailsFilterAction();
    this.filterStudents({
      resource: "students/emails",
      filter: { calculateSaldo: true, showArchived: false },
    });

    if (this.mailboxLocalFilter instanceof Object) {
      this.selectedMailboxModel = this.mailboxLocalFilter.mailBox;
      this.selectedEmailsModel = this.mailboxLocalFilter.emails;
      this.search = this.mailboxLocalFilter.searchTerm;
    } else {
      this.selectedMailboxModel = this.mailboxes[0];
    }

    if (this.getStudent && !this.isObjectEmpty(this.getStudent)) {
      const options = {
        resource: "cost-bearers/student",
        id: this.getStudent.id,
      };
      this.findCostBearerByStudentId(options);
      this.showNewEmailForm = true;
      this.studentEmail = this.getStudent ? this.getStudent.email : null;
      this.studentBlobFile = this.getBlob ? this.getBlob : null;
      this.studentMimeType = this.getMimeType ? this.getMimeType : null;
    }

    if (this.getCostBearerInfo && !this.isObjectEmpty(this.getCostBearerInfo)) {
      this.showNewEmailForm = true;
      this.studentBlobFile = this.getBlob ? this.getBlob : null;
      this.studentMimeType = this.getMimeType ? this.getMimeType : null;
      this.costBearerEmail = this.getCostBearerInfo ? this.getCostBearerInfo : null;
    }

    this.findAllTestingOrganizations({
      resource: "testing-organizations",
      filter: { showArchived: false },
    });
  }

  public mounted() {
    this.$root.$on(["reply", "reply-all", "forward"], (e: any) => {
      this.createAnswerBody();
      this.mailOption = { ...e, body: this.htmlReplyHeader, hasOptions: true };
      this.resetAllShowFlags();
      this.showNewEmailForm = true;
    });

    this.$root.$on("open-email-form", (email: any) => {
      this.mailOption = { hasOptions: true, cc: "", to: email, body: "", emailTemplate: null, emailTemplateId: 0 };
      this.showNewEmailForm = true;
    });
    this.$root.$on("form-email-close", () => {
      this.showNewEmailForm = false;
    });

    this.$watch(
      () => this.getAttachExamDocument,
      (uuid: string | null) => {
        if (uuid) {
          this.showNewEmailForm = true;
          this.studentBlobFile = this.getBlob || null;
          this.studentMimeType = this.getMimeType || null;
          this.setAttachExamDocument(null);
        }
      },
      {
        immediate: true,
      }
    );
  }

  public createAnswerBody() {
    const email = this.selectedEmailContent;
    this.htmlReplyHeader =
      "<p></p>" +
      "<p>---------------</p>" +
      "<div><strong>From: </strong>" +
      email.from +
      "</div><div><strong>To: </strong>" +
      email.to +
      "</div><div><strong>CC: </strong>" +
      email.cc +
      "</div><div><strong>Subject: </strong>" +
      email.subject +
      "</div><div><strong>Date: </strong>" +
      email.date +
      " " +
      this.time(email.time).format("hh:mm") +
      "</div><quote><p>" +
      email.body +
      "</p></quote>";
  }

  public async getAllMails(): Promise<void> {
    const params = {
      searchTerm: this.search || "",
      offset: this.page || 0,
      limit: this.limit || 10,
      emailConfigIds: this.selectedEmailIdsNoMarked || [0],
      folderTypeId: this.selectedMailboxModel.id || 1,
      marked: this.isMarkedEmailSelected,
    };
    await this.filterByMailboxAction(params);
  }

  public resetAllShowFlags(): void {
    this.showNewEmailForm = false;
    this.showCurrentEmailForm = false;
    this.showTemplatesForm = false;
  }

  public async onRowClicked(e: any): Promise<void> {
    const email = { email: e.from, id: e.id };
    if (this.selectedEmailsModel.length === 0) {
      this.setSelectedTargetEmailsMutation(email);
    }
    this.selectedEmailId = e.id;
    this.setSelectedEmailContent(e);
    this.resetAllShowFlags();
    const params = {
      emailId: e.id,
      action: "read_1",
    };
    await this.switchMailStatusAction(params);
    this.showCurrentEmailForm = true;
  }

  public onCreateClick(e: any): void {
    this.resetAllShowFlags();
    this.showNewEmailForm = true;
    this.mailOption = { hasOptions: false, cc: "", to: "", body: "" };
    this.$root.$emit("form-reset");
  }

  public onRemoveEmail() {
    if (!this.selectedEmailId) return;
    this.removeEmailAction(this.selectedEmailId);
    this.getAllMails();
  }

  private saveLocalFilter() {
    this.setMailboxLocalFilter({ emails: this.selectedEmailsModel, mailBox: this.selectedMailboxModel, searchTerm: this.search });
  }

  public onSelectEmails(e: any) {
    this.page = 0;
    this.setSelectedTargetEmailsMutation(this.selectedEmailsModel);
    this.saveLocalFilter();
    this.getAllMails();
  }

  public onSelectMailbox() {
    this.page = 0;
    this.saveLocalFilter();
    if (this.selectedMailboxModel) {
      this.getAllMails();
    }
  }

  public onSearch(e: any) {
    this.page = 0;
    this.debouncer(() => {
      this.saveLocalFilter();
      this.getAllMails();
    });
  }

  public changeMailStatus(e: any) {
    if (!this.selectedEmailId) return;
    const params = {
      emailId: this.selectedEmailId,
      action: e,
    };
    this.switchMailStatusAction(params);
  }

  public async onSetRequestQuery(e: any) {
    if (this.once) {
      this.once = false;
      await this.fetchData();
    }

    this.page = e.offset;
    this.limit = e.limit;
    await this.getAllMails();
  }

  public get emailCongIds(): Array<any> {
    if (this.selectedEmailIdsNoMarked.length) {
      return this.selectedEmailIdsNoMarked;
    }
    return this.getEmailFilterIds;
  }

  public async onRefresh(): Promise<void> {
    const ids = this.emailCongIds.filter((x: number | null | undefined) => x);

    await this.forceSyncByEmailConfigIds(ids);

    if (this.getForceSyncByEmailConfigIdsSuccess) {
      this.getAllMails();
    }
  }

  public onOpenTemplateSettings() {
    this.$router.push({ name: "EmailTemplates" });
  }

  public emailSent(): void {
    this.showNewEmailForm = false;
    this.getAllMails();
  }

  public beforeDestroy() {
    this.setStudentInfo(null);
    const options = {
      blob: null,
      mimeType: null,
    };
    this.setBlobFile(options);
  }

  public isObjectEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }
}
