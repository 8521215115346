














































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import FileButton from "@/components/Button/FileButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import UploadButton from "@/components/Button/UploadButton.vue";
import SendMailButton from "@/components/Button/SendMailButton.vue";
import { namespace } from "vuex-class";
import FscMultiselectScroll from "@/components/FscMultiselectScroll.vue";
import { EmailTypeEnum } from "@/enums/EmailTypeEnum";
import _ from "lodash";
import { Validations } from "vuelidate-property-decorators";
import Validation from "@/components/Validation.vue";
import { required } from "vuelidate/lib/validators";
import { formatStudentName } from "@/utils/NameUtil";

const MailboxModule = namespace("mailbox");
const StudentModule = namespace("student");
const RegionSpecificDocumentsModule = namespace("region-specific-documents");
const DocumentModule = namespace("document");
const TestingOrganizationsModule = namespace("testing-organizations");

@Component({
  components: {
    Validation,
    FscSimpleCard,
    FscMultiselect,
    FileButton,
    AbortButton,
    UploadButton,
    SendMailButton,
    FscMultiselectScroll,
  },
})
export default class CreateNewEmailForm extends Vue {
  public name = "CreateNewEmailForm";

  @DocumentModule.Action("findAll")
  public findAllMassEmailDocuments: any;

  @DocumentModule.Getter("getDataList")
  public allMassEmailDocuments: any;

  @RegionSpecificDocumentsModule.Action("findAll")
  public findAllRegionSpecificDocuments: any;

  @RegionSpecificDocumentsModule.Getter("getDataList")
  public allRegionSpecificDocuments: any;

  @MailboxModule.Action("getEmailTemplatesAllAction")
  private getEmailTemplatesAction: any;

  @MailboxModule.Action("sendEmailAction")
  private sendEmailAction: any;

  @MailboxModule.Getter("getSuccess")
  public emailSuccess: any;

  @MailboxModule.Getter("getIsLoading")
  public emailLoading: any;

  @MailboxModule.Getter("getEmailFilterNoMarked")
  private senderEmails: any;

  @MailboxModule.Getter("getSelectedEmailContent")
  private emailContent: any;

  /** TODO uncomment this section after email templates are available */
  @MailboxModule.Getter("getEmailTemplates")
  private emailTemplates: any;

  @TestingOrganizationsModule.Action("filter")
  public findAllTestingOrganizations: any;

  @TestingOrganizationsModule.Getter("getDataList")
  public testingOrganizations: any;

  @Prop({ type: Object, default: () => ({}) })
  public mailOption?: any;

  @Prop()
  public studentEmail!: any;

  @Prop()
  public studentBlobFile!: any;

  @Prop()
  public studentMimeType!: any;

  @Prop()
  public emailType!: any;

  @Prop()
  public selectedRow!: any;

  @Prop()
  public allStudentsWithEmail!: any;

  @Prop()
  public costBearer!: any;

  @Prop()
  public allStudentsWithEmailLoading!: any;

  @Prop()
  public emailSenderLoading!: any;

  @Prop()
  public costBearerEmail!: any;

  public customHeight = false;

  public formEmail: any = {
    sender: "",
    receiver: [],
    emailTemplate: null,
    documentTemplate: null,
    body: "",
    subject: "",
    uploadedFiles: [],
    repliedEmailId: 0,
    repliedAllEmailId: 0,
    forwardedEmailId: 0,
    regionSpecificDocumentIds: [],
    documentTypeIds: [],
  };

  @Validations()
  public validations() {
    return {
      formEmail: {
        body: { required },
        subject: { required },
        sender: { required },
        // receiver: {
        //   required,
        //   $each: {
        //     email,
        //   },
        // },
      },
    };
  }

  public allDocuments: any = [];
  public selectedDocuments: any = [];

  public get filenames(): Array<string> {
    return this.formEmail.uploadedFiles?.map((file: any) => file.name) || [];
  }

  public get studentEmailsOptions(): Array<any> {
    let allOptions: any = [];
    if (this.allStudentsWithEmail && this.allStudentsWithEmail.length > 0) {
      this.allStudentsWithEmail?.map((student: any) => {
        const email = {
          name: formatStudentName(student.firstName, student.lastName),
          email: student.email,
        };
        allOptions.push(email);
      });
    }
    if (this.testingOrganizations && this.testingOrganizations.length > 0) {
      this.testingOrganizations.map((org: any) => {
        const email = {
          name: org.name ? org.name : "",
          email: org.email ? org.email : "",
        };
        allOptions.push(email);
      });
    }
    return allOptions;
  }

  protected get formHasError() {
    const requiredFields = ["subject", "body", "receiver"];
    return requiredFields.some((key) => (!this.formEmail[key] || this.formEmail?.receiver?.length === 0 ? true : false));
  }
  public mounted() {
    this.findAllMassEmailDocuments({ resource: "documents/documents-for-mass-mail" });
    this.findAllRegionSpecificDocuments({ resource: "region-specific-documents/driving-school/current" });
    this.getEmailTemplatesAction();
    this.findAllTestingOrganizations({
      resource: "testing-organizations",
      filter: { showArchived: false },
    });
    this.onUpdateOptions();
    this.$root.$on("form-reset", this.formReset);
    this.$emit("on-mounted");
  }

  public onUpdateOptions() {
    if (this.mailOption.hasOptions) {
      if (!this.emailType) {
        this.formEmail.receiver = this.mailOption.to ? [this.mailOption.to] : "";
      }
      this.formEmail.body = this.mailOption.body || "";
      this.formEmail.repliedEmailId = this.mailOption.repliedEmailId || null;
      this.formEmail.repliedAllEmailId = this.mailOption.repliedAllEmailId || null;
      this.formEmail.forwardedEmailId = this.mailOption.forwardedEmailId || null;
      this.formEmail.subject = this.mailOption.subject || "";
      this.formEmail.emailTemplate = this.mailOption?.emailTemplate || null;
      this.formEmail.emailTemplateId = this.mailOption?.emailTemplateId || 0;
    }
  }

  public formReset() {
    this.formEmail = {
      sender: "",
      receiver: [],
      emailTemplate: null,
      documentTemplate: null,
      body: "",
      uploadedFiles: [],
      emailConfigId: 0,
      repliedEmailId: 0,
      repliedAllEmailId: 0,
      forwardedEmailId: 0,
      emailTemplateId: 0,
      regionSpecificDocumentIds: [],
      documentTypeIds: [],
    };
  }

  public onSelectEmail(): void {
    //console.log();
  }

  public onSelectEmailTemplate() {
    this.formEmail.subject = this.formEmail.emailTemplate.subject;
    this.formEmail.body = this.formEmail.emailTemplate.body;
    this.formEmail.emailTemplateId = this.formEmail.emailTemplate.id;
  }

  public onSetEmailBody(e: any) {
    this.formEmail.body = e;
  }

  public onSelectFile(e: any) {
    if (!e) return;
    let fileArray = Array.from(e.target.files);
    fileArray.map((file: any) => {
      this.formEmail.uploadedFiles.push(file);
    });
  }

  public removeSelectedFile(ind: number) {
    this.formEmail.uploadedFiles.splice(ind, 1);
  }

  public async onEmailSend(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.$toasted.error("Du hast keine Absendermailadresse eingetragen!");
      return;
    }

    const receiversArray = this.formEmail.receiver.map((receiver: any) => {
      if (receiver && receiver.email) {
        return receiver.email;
      }
      return receiver;
    });

    let regionSpD = [];
    let massEmD = [];

    if (this.selectedDocuments && this.selectedDocuments.length > 0) {
      regionSpD = this.selectedDocuments.filter((document: any) => document.regionSpecific === true).map((doc: any) => doc.id);
      massEmD = this.selectedDocuments.filter((document: any) => document.regionSpecific === false).map((doc: any) => doc.id);
    }

    const emails = receiversArray.join();

    if (emails) {
      const formData = new FormData();
      this.formEmail.uploadedFiles?.forEach((file: any) => {
        formData.append("files", file);
      });
      const data = {
        emailConfigId: this.formEmail.sender.id,
        repliedEmailId: this.formEmail.repliedEmailId,
        repliedAllEmailId: this.formEmail.repliedAllEmailId,
        forwardedEmailId: this.formEmail.forwardedEmailId,
        to: this.formEmail.receiver ? emails : "",
        cc: "",
        subject: this.formEmail.subject || "(No subject)",
        body: this.formEmail.body,
        emailTemplateId: this.formEmail.emailTemplateId,
        regionSpecificDocumentIds: regionSpD,
        documentTypeIds: massEmD,
      };
      formData.append("email", JSON.stringify(data));
      await this.sendEmailAction(formData);

      if (this.emailSuccess === true) {
        this.$emit("send-email");
      }
    } else {
      this.$toasted.error("Die eingegebene E-Mail Adresse muss ausgewählt oder mit ‘Enter’ bestätigt werden!");
    }
  }

  public onAbort() {
    this.formReset();
    this.$root.$emit("form-email-close");
  }

  public get formEmailComputed() {
    switch (this.emailType) {
      case EmailTypeEnum.SELECTED_STUDENT: {
        const studentFiltered = this.allStudentsWithEmail.filter((student: any) => student.email === this.selectedRow.email);
        this.formEmail.receiver = studentFiltered[0]?.email ? [studentFiltered[0].email] : [];
        return this.formEmail;
      }
      case EmailTypeEnum.ALL_STUDENT: {
        this.formEmail.receiver = this.allStudentsWithEmail.map((student: any) => {
          return student.email ? student.email : "";
        });
        return this.formEmail;
      }
      default:
        if (this.studentEmail) {
          this.formEmail.receiver = [this.studentEmail];
          return this.formEmail;
        } else {
          this.formEmail.receiver = [];
          return this.formEmail;
        }
    }
  }

  @Watch("mailOption", { deep: true, immediate: true })
  handler(val: any) {
    this.onUpdateOptions();
  }

  @Watch("studentEmail", { deep: true, immediate: true })
  public onStudentEmailSet(email: any): void {
    if (email) {
      this.formEmail.receiver = [email];
    }
  }

  @Watch("studentBlobFile", { deep: true, immediate: true })
  public onStudentBlobFileSet(blob: any): void {
    if (blob) {
      let mimeType = this.studentMimeType?.split("/");
      const myFile = new File([blob], `Documente.${mimeType?.[1]}`, {
        type: this.studentMimeType,
      });
      this.formEmail.uploadedFiles.push(myFile);
    }
  }

  @Watch("allMassEmailDocuments")
  public onMassEmailDocumentsChange(massEmailDocuments: any) {
    if (massEmailDocuments && massEmailDocuments.length > 0) {
      massEmailDocuments.map((document: any) => {
        document.regionSpecific = false;
        this.allDocuments.push(document);
      });
    }
  }

  @Watch("allRegionSpecificDocuments")
  public onAllRegionSpecificDocumentsChange(regionSpecificDocuments: any) {
    if (regionSpecificDocuments && regionSpecificDocuments.length > 0) {
      regionSpecificDocuments.map((document: any) => {
        document.regionSpecific = true;
        this.allDocuments.push(document);
      });
    }
  }

  @Watch("costBearer", { immediate: true })
  public onCostBearer(costBearer: any) {
    if (this.formEmail.receiver.length > 1 && this.emailType === 1) {
      let sliced = this.formEmail.receiver.slice(0, 1);
      this.formEmail.receiver = sliced;
    }
    if (costBearer && costBearer.length > 0) {
      const filtered = _.uniqBy(costBearer, (obj: any) => obj.id);
      filtered.map((bearer: any) => {
        const costBearerEmail = {
          name: bearer.name ? `${bearer.name} RZ` : "RZ",
          email: bearer.email,
        };
        this.studentEmailsOptions.push(costBearerEmail);
        if (this.emailType === 1) {
          this.formEmail.receiver.push(costBearerEmail);
        }
        if (this.studentEmail) {
          this.formEmail.receiver.push(costBearerEmail);
        }
      });
    }
  }

  @Watch("costBearerEmail", { deep: true, immediate: true })
  public onCostBearerEmailSet(email: any): void {
    if (email) {
      const costBearerEmail = {
        name: email.name ? `${email.name} RZ` : "RZ",
        email: email.email,
      };
      this.studentEmailsOptions.push(costBearerEmail);
      this.formEmailComputed.receiver.push(costBearerEmail);
    }
  }

  @Watch("formEmail", { deep: true, immediate: true })
  public onFormEmailWatch(form: any) {
    if (form && form.receiver.length > 15) {
      this.customHeight = true;
    } else {
      this.customHeight = false;
    }
  }
}
