






import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ButtonMixin from "@/mixins/ButtonMixin";
import i18n from "@/i18n";

@Component
export default class ReplyButton extends mixins(ButtonMixin) {
  public name = "ReplyButton";

  @Prop({ default: () => i18n.t("general.button_reply") })
  public tooltipLabel!: string;
}
