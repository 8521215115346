














import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ButtonMixin from "@/mixins/ButtonMixin";
import i18n from "@/i18n";

@Component({})
export default class DropdownVerticalDotsButton extends mixins(ButtonMixin) {
  public name = "DropdownVerticalDotsButton";

  @Prop({ default: () => i18n.t("general.button_actions") })
  public tooltipLabel!: string;

  @Prop({ default: () => [] })
  public options!: [];

  public onClick(e: any) {
    this.$emit("click", e);
  }
}
