







import { Component, Prop, Vue } from "vue-property-decorator";
import ActionButton from "@/components/Button/ActionButton.vue";

@Component({
  components: {
    ActionButton,
  },
})
export default class RefreshButton extends Vue {
  public name = "RefreshButton";

  @Prop({ default: () => false, type: Boolean })
  public disabled!: boolean;
}
